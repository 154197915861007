import React from 'react';
// import { useStaticQuery, graphql } from "gatsby"
import Img from 'gatsby-image';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import options from '../../templates/richTextRenderers';

const useStyles = props =>
  makeStyles(theme => ({ 
    text:{
      fontSize: '1.1875rem',
},
}));

const BlogPost = ( props ) => {

const blogData = props.data
const classes = useStyles({ ...props, data: blogData })();

  return (
<Box mt={13}>
  <Grid container>
    <Grid item xs={1}></Grid>  
    <Grid item xs={10}>
      <Img fluid={{...blogData.image.fluid, aspectRatio: 16 / 7 }} />
    </Grid>
    <Grid item xs={1}></Grid>


    <Grid item xs={1} sm={2} md={3}>
    </Grid>
    <Grid item xs={10} sm={8} md={6}>
      <Grid item xs={12}>
        <h1>{blogData.title}</h1>
      </Grid>
      <Grid item xs={12}>
        <h2>{blogData.subtitle}</h2>
      </Grid>
      <Grid item xs={12}>
      <h4><span style={{color:'#B90605'}}> {blogData.author}</span><span style={{color:'#4B4F56'}}>{' - '}{blogData.datePosted}{' '}·{' '}{blogData.timeToRead}</span></h4> 
    </Grid>
    <Grid item xs={12}>
    <Typography component='span' className = {classes.text} gutterBottom>
      {documentToReactComponents(blogData.blogContent.json, options())}
    </Typography>     
    </Grid>
    </Grid>
    <Grid item xs={1} sm={2} md={3}>
    </Grid>


  </Grid>
</Box>
  );
};

export default BlogPost;
