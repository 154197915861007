import React from 'react'
import { useStaticQuery, graphql } from "gatsby"

import ContentfulComponentFooter from '../../ContentfulComponents/ContentfulComponentFooter';

export default function Footer( props ) {

  const data = useStaticQuery(graphql`
  query FooterQuery{
    contentfulComponentFooter4: contentfulComponentFooter (contentful_id: {eq: "6xCfAFAjv3Ox5hll40c8JD"}) {
      id
      title
      title2
      address
      city
      postalCode
      country
      email
      facebookLink
      twitterLink
      linkedinLink
      instagramLink
      options {
        ... on ContentfulPage {
        available
        id
        name
        title
        menuText
        contentful_id
        }
        ... on ContentfulComponentPdfLink {
          id
          available
          menuTitle
          pdfLink
        }
      }
      logo {
        fluid {
          ...GatsbyContentfulFluid
        }
      }
      companyRegistrationTitle
      companyRegistrationNumber
      registeredCharityTitle
      registeredCharityNumber
      disclaimer {
        json
      }
    }
  }
  `)
  return (
    <ContentfulComponentFooter {...props} data={data} componentId="contentfulComponentFooter4" />
  )
}