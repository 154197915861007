import React from 'react'
import { useStaticQuery, graphql } from "gatsby"

import ContentfulComponentTopBarNavigation from '../../ContentfulComponents/ContentfulComponentTopBarNavigation';

export default function Header( props ) {

  const { extraData } = props

  const data = useStaticQuery(graphql`
  query {
    contentfulComponentTopBarNavigation0: contentfulComponentTopBarNavigation (contentful_id: {eq: "eqtJB9MC57s8T9Bki5KQF"}) {
      id
      name
      logo {
        fluid {
          ...GatsbyContentfulFluid
        }
      }
      buttontext
      linkPath
      options {
        available
        id
        name
        title
        menuText
        order
        contentful_id
      }
      button {
        ... on ContentfulPage {
        available
        id
        name
        title
        buttonColor
        menuText
        contentful_id
        }
        ... on ContentfulComponentPdfLink {
          id
          available
          menuTitle
          pdfLink
        }
      }
      contentful_id
    }
  }
  `)
  return (
    <ContentfulComponentTopBarNavigation {...props} data={data} extraData={extraData} componentId="contentfulComponentTopBarNavigation0" />
  )
}
